<template>
  <div :class="role ? 'container_is_auth' : 'container_no_auth'">
    <Nav v-if="!role" />
    <div :class="role ? 'child_is_auth' : 'child_no_auth'">
      <h2 class="text-center">Merchandise</h2>
      <v-divider></v-divider>
      <div v-if="!loading">
        <section class="" v-if="merchandise">
          <div class="wrapper">
            <div v-for="item in merchandise.data" :key="item.id" class="ma-5">
              <v-hover v-slot="{ hover }">
                <v-card height="350px" width="300px">
                  <v-img
                    :src="`${env}/merchant/upload/${item.gambar}`"
                    class="img_merch"
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="
                          transition-fast-in-fast-out
                          black
                          v-card--reveal
                          white--text
                          d-flex
                          justify-center
                          align-center
                        "
                        style="height: 100%"
                      >
                        <div class="text-center pa-3">
                          <h4>{{ item.judul }}</h4>
                          <h3>
                            Rp
                            {{
                              item.harga
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                            }}
                          </h3>
                          <div class="mt-3 d-flex justify-center">
                            <v-btn
                              depressed
                              dark
                              color="orange"
                              @click="checkLink(item.link)"
                              >Buy Now</v-btn
                            >
                          </div>
                        </div>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
            </div>
          </div>
          <section
            class="pa-2 d-flex justify-center"
            style="width: 100%"
            v-if="!merchandise.data.length"
          >
            <div>
              <img src="../../assets/img/404.svg" height="100px" alt="404" />
              <h3>No Data Found!</h3>
            </div>
          </section>
        </section>
      </div>
      <PrevLinkMerchant
        v-bind:dialogLinkmerchant="dialogLinkmerchant"
        v-bind:link="link"
        @close="closeDialog"
      />
      <div v-if="loading" class="mt-5">
        <v-row>
          <v-col md="3" lg="3" sm="6" xs="6" v-for="i in 4" :key="i">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PrevLinkMerchant from "../../components/General/Modal/prevLinkMerchant.vue";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { Nav, PrevLinkMerchant },
  name: "allebook",
  data() {
    return {
      item: null,
      find: "",
      loading: false,
      page: 1,
      limit: 30,
      dialogLinkmerchant: false
    };
  },
  computed: {
    ...mapState({
      merchandise: state => state.merchandise.listMerch,
      env: state => state.API_URL,
      role: state => state.role
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      let data = {
        page: this.page,
        per_page: this.limit,
        search: this.find
      };
      this.loading = true;
      this.$store.dispatch("merchandise/listMerchandise", data).then(data => {
        console.log(data);
        this.loading = false;
      });
    },
    checkLink(link) {
      this.link = JSON.parse(link);
      this.dialogLinkmerchant = true;
    },
    closeDialog() {
      this.dialogLinkmerchant = false;
    }
  }
};
</script>

<style scoped>
.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: auto;
  width: 80%;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.img_merch {
  width: 300px;
  height: 350px;
  object-fit: cover;
}
</style>
