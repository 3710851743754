<template>
  <v-row justify="start">
    <v-dialog v-model="dialogLinkmerchant" persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Get It On</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <div class="w_" v-if="link">
              <div v-for="(item, idx) in link" :key="idx">
                <div class="temp_link">
                  <a :href="item.link" target="_blank">
                    <img :src="item.img" height="70px" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "linkMerchant",
  props: ["dialogLinkmerchant", "link"],

  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.w_ {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.temp_link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
}
.temp_link:hover {
  background: rgba(128, 128, 128, 0.167);
  cursor: pointer;
}
</style>
